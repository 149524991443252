.my-loading-box {
  /*position: absolute;*/
  position: fixed;
  top: 0;
  left: 0;
  z-index: 8;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.28);
}
/*.my-loading {*/
/*  width: 100rpx;*/
/*  height: 100rpx;*/
/*  border-radius: 50%;*/
/*  background-image: url("https://oss.gsteps.cn/mini/loading/loading.gif");*/
/*  background-color: #ffbc00;*/
/*  background-repeat: no-repeat;*/
/*  background-size: contain;*/
/*  background-position: center;*/
/*}*/

@keyframes lds-disk {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes lds-disk {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.lds-disk {
  position: relative;
}
.lds-disk > div div:nth-child(1) {
  position: absolute;
  top: 70px;
  left: 70px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  /*background: rgba(0, 0, 0, 0.55);*/
  background: #566270;
  box-shadow: 1px 1px 10px #000;

  /*border: 1rpx solid #ffb600;*/
}
.lds-disk > div div:nth-child(2) {
  position: absolute;
  top: 74.2px;
  left: 91px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: #ffb600;
  /*background: #bf271d;*/
  line-height: 18px;
  font-size: 5px;
  color: #d0a700;
  -webkit-animation: lds-disk .8s linear infinite;
  animation: lds-disk .8s linear infinite;
  -webkit-transform-origin: 9px 25.8px;
  transform-origin: 9px 25.8px;
}
.lds-disk {
  width: 200px !important;
  height: 200px !important;
  -webkit-transform: translate(-100px, -100px) scale(1) translate(100px, 100px);
  transform: translate(-100px, -100px) scale(1) translate(100px, 100px);
}
