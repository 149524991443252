@import "../../style/var";

.home-live-comment {
  //padding-top: 100px;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color: #171b24;
  font-family: PingFang SC,Helvetica Neue,Helvetica,Arial,Hiragino Sans GB,Microsoft Yahei,\\5FAE\8F6F\96C5\9ED1,STHeiti,\\534E\6587\7EC6\9ED1,sans-serif;
  img {
    max-width: 100%;
  }

  .video-js {
    background-color: transparent;
  }

  &.closing {
    .comment-box {
      opacity: .2;
    }

    .top-opt-box {
      .teacher, .user-box {
        opacity: 0;
      }
      .close {
        background-image: url("../../static/img/live/close_cricle-down.png");
      }
    }

  }
  .top-opt-box {
    position: absolute;
    top: 0;
    padding: 10px 15px;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    .avatar {
      width: 30px;
      height: 30px;
      min-width: 30px;
      border-radius: 50%;
      background-color: #d8d8d8;
      overflow: hidden;
      display: inline-block;
    }
    .teacher-list {
      display: flex;
      align-items: center;

      .teacher {
        margin: 0 10px 0 0;
        display: flex;
        align-items: center;
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 20px;
        //padding: 0 10px;
        .teacher-name {
          color: #FFB600;
          padding: 0 4px;
          font-weight: bolder;
        }
        flex-grow: 0;
      }
      .course-name {
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 20px;
        height: 30px;
        line-height: 30px;
        padding: 0 20px;
        color: #FFB600;
      }
    }


    .user-box {
      position: relative;
      padding: 0 4px;
      flex-grow: 1;
      overflow: hidden;
      height: 30px;
      .user {
        margin: 0 1px;
      }
      .member-info {
        position: absolute;
        right: 4px;
        top: 0;
        padding: 0 4px;
        border-radius: 6px;
        height: 100%;
        color: #FFB600;
        font-size: 12px;
        line-height: 30px;
        background-color: rgba(0, 0, 0, 0.91);
      }
    }

    .close {
      flex-grow: 0;
      width: 30px;
      height: 30px;
      border: 1px solid #FFB600;
      color: #FFB600;
      line-height: 30px;
      padding: 0 10px;
    }
  }

  .comment-box {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 0 10px 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column-reverse;

    .notice {
      color: #FFB600;
      font-size: 24px;
      width: 70%;
      min-width: 350px;
      background-color: rgba(0, 0, 0, 0.8);
      border-radius: 10px;
      padding: 4px 10px;
      line-height: 1.6em;
      margin-bottom: 3px;
      box-sizing: border-box;
    }

    .comment-list {
      //opacity: 0.6;
      .comment {
        font-size: 24px;
        line-height: 40px;
        margin: 2px 0;
        padding: 4px 10px;
        background-color: rgba(0, 0, 0, 0.5);
        //background-color: rgba(178, 178, 178, 0.5);
        border-radius: 10px;
        color: #D8D8D8;
        position: relative;

        .name {
          color: #FFB600;
          padding-right: 5px;
        }
        .text {
          word-break:break-all;
        }
      }
      .time {
        //position: absolute;
        //right: -100px;
        //top:0;
        //z-index: 10;
        color: #fff;
        font-size: 12px;
        padding-left: 10px;
      }


    }

    .comment-input {
      //position: absolute;
      //bottom: 20px;
      //background-color: rgba(255, 255, 255, 0.1);
      background-color: rgba(0, 0, 0, 0.5);
      border: 1px solid #303030;
      border-radius: 30px;
      width: 100%;
      box-sizing: border-box;
      margin: 10px 0 0;
      padding: 4px 10px;
      input {
        font-size: 20px;
        display: block;
        height: 32px;
        line-height: 32px;
        border: 0;
        width: 100%;
        //height: 100%;
        background: transparent;
        color: #D8D8D8;
      }
    }
  }

  .feedback-box {
    position: fixed;
    top: 70px;
    left: 10px;
    padding: 0 0 0 10px;
    width: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 30;
    .feedback {
      background-position: left center;
      background-size: contain;
      background-repeat: no-repeat;
      height: 60px;
      line-height: 60px;
      padding: 6px 0 0 80px;

      font-size: 50px;
      font-weight: bolder;
      color: #FFB600;
      background-color: rgba(23, 27, 36, 0.57);
      margin-left: 30px;
      border-radius: 6px;
      //width: 26px;
      display: inline-block;
      &.happy {
        background-image: url("../../static/img/live/happy.png");
      }
      &.sad {
        background-image: url("../../static/img/live/sad.png");
      }
    }
  }

}





