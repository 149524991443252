@import "../../style/var";

.mini-file-up {
  padding: 50px 40px;
  max-width: 800px;
  margin: 0 auto;
  .title {
    font-size: 18px;
    line-height: 1.5;
    font-weight: 400;
    text-align: center;
  }
  .sub-title {
    font-size: 14px;
    line-height: 1.5;
    font-weight: 400;
    text-align: center;
    color: #3e3e3e;
  }

  .file-btn {
    position: relative;
    margin-top: 20px;
    input {
      display: block;
      position: absolute;
      z-index: 10;
      opacity: 0;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      cursor: pointer;
    }
  }

  .show-info {
    margin-top: 18px;
    display: flex;
    align-items: center;

    .progress {
      margin-right: 5px;
      width: 100%;
    }
  }
}


