@import "./style/var";

html  {
  body {
    background-color: #fff;
  }

}
img {
  max-width: 100%;
}

.App {
  text-align: left;
}
