.icp {
  text-align: left;
  padding-left: 20px;
  font-size: 12px;
  line-height: 2em;
  // color: #bdbdbd;
  color: white;
  a {
    color: white;
  }

  &.black {
    color: #000;
    a {
      color: #000;
    }
  }
}
