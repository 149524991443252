@import "../../style/var";

.wx-home-box {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
}
.wx-home {
  font-family: PingFang SC,Helvetica Neue,Helvetica,Arial,Hiragino Sans GB,Microsoft Yahei,\\5FAE\8F6F\96C5\9ED1,STHeiti,\\534E\6587\7EC6\9ED1,sans-serif;

  max-width: 800px;
  height: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: 540px auto;
  background-position: center;
  background-repeat: repeat;
  background-image: url("../../static/img/link/bg.png");
  .content {
    width: 100%;
    .logo {
      width: 60%;
      display: block;
      margin: -20% auto 0;
    }

    .link {
      margin: 40px auto 0;
      width: 60%;
      height: 40px;
      line-height: 40px;
      border-radius: 10px;
      box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.19);
      text-align: center;
      background: #ffb600;
      color: #141414;
      font-size: 14px;
      a {
        color: #141414;
        width: 100%;
        height: 100%;
        display: block;
      }
    }
  }


}


