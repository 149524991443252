@import "../../style/var";

.home {
  font-family: PingFang SC,Helvetica Neue,Helvetica,Arial,Hiragino Sans GB,Microsoft Yahei,\\5FAE\8F6F\96C5\9ED1,STHeiti,\\534E\6587\7EC6\9ED1,sans-serif;

  max-width: 800px;
  margin: 0 auto;

  .logo {
    padding: 0 0 0;
    //width: 100px;
    display: block;
    margin: 0 auto;
  }

  .desc {
    padding: 30px 20px;
    line-height: 1.7em;
    font-size: 16px;
    max-width: 813px;
    display: block;
    margin: 0 auto;
    color: #4e4e4e;
    font-weight: bolder;
  }
  .store-list {
    display: block;
    margin: 0 auto;
  }
}


