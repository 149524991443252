@import "../../style/var";

.back-mini-program {
  position: fixed;
  top: 10px;
  left: 0;
  background-color: #202632;
  z-index: 100;
  padding: 6px 8px;
  font-size: 14px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items:center;
  border-radius: 0 20px 20px 0;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.87);

  img {
    height: 100%;
    border-radius: 50%;
  }
  .back-img {
    height: 65%;
    margin-right: 5px;
  }

  span {
    color: $yellow;
    line-height: 30px;
  }
}
