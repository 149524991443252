.course-detail-container {
  max-width: 680px;
  margin: 0 auto;

  /* 课程预告视频 */
  .course-video {
    position: relative;
    width: 100%;
    min-height: 180px;
    text-align: center;
    background: rgba(0, 0, 0, .1);
  }

  .course-video .video-paly {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40px;
    height: 40px;
    background: url(../../static/img/live/play.png) no-repeat;
    background-size: 40px;
    background-position: center;
  }

  /* 课程详情页介绍 */
  .course-detail-content {
    padding: 20px;
    box-sizing: border-box;
  }

  /* 标题 */
  .headline h3 {
    margin: 0 0 5px;
  }

  .headline .txt {
    color: #737FA3;
    font-weight: bold;
    margin: 0;
  }

  /* 内容 */
  .intro {
    font-size: 14px;
    line-height: 22px;
    font-weight: 500;
    color: #737FA3;
  }

  .intro .title {
    text-align: center;
    margin: 20px 0;
  }
  
  .intro .title label {
    position: relative;
    color: #202632;
    font-weight: bold;
    z-index: 1;
  }

  .intro .title label::after {
    position: absolute;
    content: '';
    bottom:0;
    left: -10%;
    right: 0;
    width: 120%;
    height: 10px;
    border-radius: 6px;
    background: #FFB600;
    z-index: -1;
  }

  .intro .content {
    padding: 10px;
    box-sizing: border-box;
  }

  /* 图片容器 */
  .intro .poster {
    font-size: 0;
    line-height: 0;
    padding: 0;
    margin: 0;
    text-align: center;
  }

  .intro .poster img {
    box-shadow: 1px 1px 10px rgba(0, 0, 0, .3);
    border-radius: 5px;
  }

  /* 日历卡片-直播开始时间 */
  .intro .calendar-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #544726;
    border-radius: 5px;
    border: 1px solid #D8D8D8;
    padding: 10px;
    box-sizing: border-box;
    max-width: 180px;
    margin: 0 auto;
  }

  .intro .calendar-card .date {
    font-size: 20px;
    font-weight: bolder;
    margin: 0;
  }

  .intro .calendar-card .time {
    font-size: 14px;
    font-weight: bolder;
    margin: 0;
  }

  .intro .calendar-card .weekday {
    position: relative;
    padding-left: 10px;
    margin-left: 10px;
    font-size: 24px;
    font-weight: bolder;
  }

  .intro .calendar-card .weekday p {
    margin: 5px 0;
  }

  .intro .calendar-card .weekday::before {
    position: absolute;
    content: '';
    left: -10px;
    top: 5px;
    bottom: 5px;
    width: 8px;
    background: #D8D8D8;
    border-radius: 8px;
  }

  /* 小程序二维码入口 */
  .mini-qrcode {
    display: flex;
    justify-content: space-between;
    padding: 30px;
    box-sizing: border-box;
    background: #FFB600;
  }

  .mini-qrcode .qrcode-img {
    flex-shrink: 0;
    background: white;
    width: 100px;
    border-radius: 5px;
    border: 3px solid white;
  }

}
