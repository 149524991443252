@import "../../style/var";

@keyframes sun {
  0% {
    //transform: translate(60px, 0);
    transform: rotate(200deg);
  }
  100% {
    //transform: translate(0,0);
    transform: rotate(0);
  }
}
@keyframes moon {
  0% {
    //transform: translate(60px, 0);
    transform: rotate(-200deg);
  }
  100% {
    //transform: translate(0,0);
    transform: rotate(0);
  }
}

@keyframes click {
  //0% {
  //  transform: scale(.7);
  //}
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}



.home-live {
  //padding-top: 100px;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color: #171b24;

  font-family: PingFang SC,Helvetica Neue,Helvetica,Arial,Hiragino Sans GB,Microsoft Yahei,\\5FAE\8F6F\96C5\9ED1,STHeiti,\\534E\6587\7EC6\9ED1,sans-serif;
  &.zhejie-live {
    //background-image: url("../../static/img/zhejie-gsteps-2.png");
    //background-repeat: no-repeat;
    //background-position: center 80px ;
    //background-size: 400px auto;
  }
  img {
    max-width: 100%;
  }

  .video-js {
    background-color: transparent;
  }

  &.closing {
    .comment-box {
      opacity: .2;
      //z-index: -1;
      //bottom: 100px;

      .opt-box {
        display: none;
      }
    }
    .opt-box-box-content {
      display: none;
    }
    .feedback-btn-box {
      opacity: .8;
      .feedback-btn {
        //display: none;
        width: 14px;
        height: 14px;
        //opacity: .5;
      }
      .feedback-text {
        //display: none;
        font-size: 12px;
      }
    }
    .close-tips {
      display: none;
    }


    .top-opt-box {
      .teacher, .user-box, .course-title {
        opacity: 0;
      }
      .close {
        //background-image: url("../../static/img/live/close_cricle-down.png");
        background-image: url("../../static/img/live/moon.png");
        animation: sun .5s ease-out;
      }

    }

    .comment-input {
      display: none!important;
    }

  }
  .top-opt-box {
    position: absolute;
    top: 0;
    padding: 10px 15px;
    box-sizing: border-box;
    z-index: 35;
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    .avatar {
      width: 30px;
      height: 30px;
      min-width: 30rpx;
      border-radius: 50%;
      background-color: #d8d8d8;
      overflow: hidden;
      display: inline-block;
    }
    .teacher {
      display: flex;
      align-items: center;
      background-color: rgba(255, 255, 255, 0.1);
      border-radius: 20px;
      margin: 0 10px 0 0;
      //padding: 0 10px;
      .teacher-name {
        color: #FFB600;
        padding: 0 4px;
        font-weight: bolder;
      }
      flex-grow: 0;
    }
    .course-title {
      background-color: rgba(255, 255, 255, 0.1);
      border-radius: 20px;
      height: 30px;
      line-height: 30px;
      padding: 0 20px;
      color: #FFB600;

      &.zhejie-title {
        height: 60px;
        width: 200px;
        background-color: rgba(255, 255, 255, 0);
        //background-image: url("../../static/img/zhejie-logo-2.png");
        background-image: url("../../static/img/zhejie-gsteps-2.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }
    }

    .user-box {
      position: relative;
      padding: 0 4px;
      flex-grow: 1;
      overflow: hidden;
      height: 30px;
      .user {
        margin: 0 1px;
      }
      .member-info {
        position: absolute;
        right: 4px;
        top: 0;
        padding: 0 4px;
        border-radius: 6px;
        height: 100%;
        color: #FFB600;
        font-size: 12px;
        line-height: 30px;
        background-color: rgba(0, 0, 0, 0.91);
      }
    }

    //.zhejie-logo {
    //  width: 100px;
    //  height: 100%;
    //  background-image: url("../../static/img/zhejie-gsteps-2.png");
    //  background-repeat: no-repeat;
    //  background-position: center;
    //  background-size: contain;
    //}

    .close {
      flex-grow: 0;
      width: 22px;
      height: 22px;
      min-width: 22px;
      min-height: 22px;
      background-image: url("../../static/img/live/sunny.png");
      background-position: center;
      background-repeat: no-repeat;
      background-size: 20px auto;
      background-color: #000000c7;
      padding: 4px;
      border-radius: 50%;
      animation: moon .5s ease-out;
      cursor: pointer;
    }
  }

  .comment-box {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 0 10px 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column-reverse;
    z-index: 20;
    .notice {
      color: #FFB600;
      font-size: 12px;
      font-weight: bolder;
      width: 200px;
      background-color: rgba(0, 0, 0, 0.8);
      border-radius: 10px;
      padding: 4px 10px;
      line-height: 1.6em;
      margin-bottom: 3px;
      box-sizing: border-box;
    }

    .comment-list {
      //opacity: 0.6;
      .comment {
        font-size: 12px;
        line-height: 20px;
        margin: 2px 0;
        padding: 4px 10px;
        background-color: rgba(0, 0, 0, 0.5);
        //background-color: rgba(178, 178, 178, 0.5);
        border-radius: 10px;
        color: #D8D8D8;
        .name {
          color: #FFB600;
          padding-right: 5px;
          font-weight: bolder;
        }
        .text {
          word-break:break-all;
        }
      }


    }

    .comment-input {
      //position: absolute;
      //bottom: 20px;
      //background-color: rgba(255, 255, 255, 0.1);
      width: 100%;
      max-width: 400px;
      margin: 10px 0 0;

      display: flex;
      position: relative;
      .feedback-tips {
        position: absolute;
        width: 124px;
        top: -50px;
        right: -2px;
        background-color: #000;
        background-color: rgba(0,0,0,.5);
        border: 1px solid #303030;
        border-radius: 6px;
        padding: 5px;
        color: #FFB600;
        font-size: 13px;
        box-shadow: 1px 1px 10px #000;
        line-height: 1.4em;
      }
      .input-box {
        background-color: rgba(0, 0, 0, 0.5);
        box-sizing: border-box;
        padding: 4px 10px;
        border: 1px solid #303030;
        border-radius: 30px;
        flex: 1;
        input {
          display: block;
          height: 26px;
          line-height: 26px;
          border: 0;
          width: 100%;
          //height: 100%;
          background: transparent;
          color: #D8D8D8;
        }
      }

      .feedback-box {
        padding: 0 0 0 10px;
        width: 70px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .feedback {
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;
          height: 26px;
          width: 26px;
          display: inline-block;
          cursor: pointer;
          &.happy {
            background-image: url("../../static/img/live/happy.png");
          }
          &.sad {
            background-image: url("../../static/img/live/sad.png");
          }

          &.active {
            animation: click ease-out .8s;
          }

        }
      }
    }
  }

}

@media screen and (max-width: 500px) {
  .home-live {
    &.zhejie-live {
      background-size: 80% auto;

      .course-title {
        &.zhejie-title {
          height: 50px;
          width: 100px;
        }
      }
    }
  }
}

.not-living {
  color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10;
  background-color: #171b24;
  font-family: PingFang SC,Helvetica Neue,Helvetica,Arial,Hiragino Sans GB,Microsoft Yahei,\\5FAE\8F6F\96C5\9ED1,STHeiti,\\534E\6587\7EC6\9ED1,sans-serif;
  img {
    max-width: 100%;
  }

  .teacher-list-box {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 60px 0 0;
    .teacher-avatar {
      margin: 0 10px 0;
      img {
        display: block;
        width: 80px;
        height: 80px;
        border-radius: 50px;
        margin: 0 auto;
        box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.87);
      }
    }
    .teacher-name {
      text-align: center;
      font-size: 14px;
      color: #FFB600;
      font-weight: bolder;
      margin: 10px 0;
    }
  }

  .course-name {
    text-align: center;
    font-size: 14px;
    color: #d6d6d6;
    font-weight: bolder;
    margin: 10px 0;
  }

  .title {
    text-align: center;
    font-weight: bolder;
    color: #d6d6d6;
    margin: 60px 0 0;
    font-size: 20px;
  }

  .reload {
    text-align: center;
    font-weight: bolder;
    color: #FFB600;
    //position: absolute;
    width: 100%;
    //bottom: 40px;
    font-size: 14px;
    padding: 30px 0 4px;
    //border-bottom: 1px solid #FFB600;
    text-decoration: underline;
  }
}
.opt-box {
  .change-status {
    display: inline-block;
    width: 100px;
    border: 1px solid #FFB600;
    color: #FFB600;
    border-radius: 10px;
    height: 26px;
    line-height: 26px;
    margin: 0px 2px 10px 0;
    text-align: center;
    background-color: rgba(41, 41, 41, 0.8);
    cursor: pointer;
  }

  &.sys-opt-box {
    .change-status {
      height: 20px;
      line-height: 20px;
      font-size: 12px;
      width: auto;
      padding: 0 5px;
      border: 1px solid #737fa3;
      color: #737fa3;
    }
  }
}

.home-live {
  display: flex;
  &>div {
    display: inline-block;
  }
}
@keyframes feedbackShow {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}
.feedback-btn-box {
  display: flex;
  align-items: center;
  border-radius: 6px;
  margin-bottom: 16px;
  color: #fff;
  background-color: #171b24;
  width: 200px;
  padding: 4px;
  &.show {
    animation: feedbackShow 1s ease-out 3;
  }
  .feedback-btn {
    width: 28px;
    height: 28px;
    border-radius: 60px;
    background-image: url("../../static/img/feedback.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 60% auto;
    background-color: #fff;
    margin-right: 8px;
  }
}

.opt-box-box-content {
  .opt-box-box {
    background-color: rgba(0, 0, 0, 0.3);
    padding: 8px 16px;
    display: inline-block;
    margin: 0 0 6px;
    border-radius: 6px;

    .opt-box-title {
      color: #ffb600;
      padding-bottom: 5px;
    }
  }
}


.close-tips {
  position: absolute;
  //width: 124px;
  top: 50px;
  right: 10px;
  background-color: #000;
  background-color: rgba(0,0,0,.5);
  border: 1px solid #303030;
  border-radius: 6px;
  padding: 5px;
  color: #FFB600;
  font-size: 13px;
  box-shadow: 1px 1px 10px #000;
  line-height: 1.4em;
}


.no-login-text {
  text-align: center;
  padding: 10px 20px;
}
.no-login-text-2 {
  text-align: left;
  //padding: 0px 10px;
}
