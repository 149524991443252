@import "../../style/var";

.info-comp {
  text-align: left;
  background: #f4f4f4;

  .card {
    position: relative;

    .card-spaceman {
      position: absolute;
      right: 10%;
      top: -9%;
      width: 36%;
    }
  }

  .top-content {
    color: #f5f5f9;
    padding: 0 33px;
    font-size: 12px;

    .info-content {

      p {
        padding: 0;
        margin: 0;
        line-height: 1.5em;
      }
    }
  }

  .am-card-body.card-content {
    padding: 8px 38px 12px;

    .card-title {
      margin: 8px 0;

      img {
        height: 20px;
      }
      p {
        font-size: 18px;
        font-weight: bolder;
      }
    }

    .card-more {
      height: 12px;
      background-image: url('../../static/img/more.png');
      background-repeat: no-repeat;
      background-size: auto 12px;
      background-position: top center;
    }

  }

  .flex-list {
    display: flex;
    .flex-left {
      display: flex;
      align-items: center;
      padding-right: 4px;
      img {
        min-width: 80px;
        width: 80px;
      }
    }

    .flex-right {
      padding-left: 5px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      p {
        padding: 0;
        margin: 0;
        line-height: 1.6em;
        font-size: 12px;

        &.title {
          font-size: 14px;
          font-weight: bolder;
        }
      }
    }
  }

  .qa {
    p {
      margin: 0;
      padding: 0;
      line-height: 1.6em;
    }
  }

  .go-mini {
    height: 40px;
    line-height: 40px;
    border-radius: 6px;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.19);
    text-align: center;
    background: #ffb600;
    color: #000;
    font-size: 14px;
    margin: 10px 0;

    a {
      color: #000;
    }
  }
}
