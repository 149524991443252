@import "../../style/var";

.home {
  font-family: PingFang SC,Helvetica Neue,Helvetica,Arial,Hiragino Sans GB,Microsoft Yahei,\\5FAE\8F6F\96C5\9ED1,STHeiti,\\534E\6587\7EC6\9ED1,sans-serif;
  .logo {
    padding: 0 0 0;
    //width: 100px;
    display: block;
    margin: 0 auto;
  }

  .desc {
    padding: 30px 20px;
    line-height: 1.7em;
    font-size: 16px;
    max-width: 813px;
    display: block;
    margin: 0 auto;
    color: #4e4e4e;
    font-weight: bolder;
  }
  .store-list {
    display: block;
    margin: 0 auto;
  }
}
.jingzi-notice {
  position: absolute;
  top: 20px;
  display: inline-block;
  padding: 10px 20px;
  //color: #56ff0d;
  color: #FFB600;
  background-color: #000;
  text-align: center;
  height: 24px;
  line-height: 24px;
  border-radius: 6px;
}

/* 镜子画像框体 */
.mirror-wrap {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
  //background: #000;
  box-shadow: 1px 5px 10px rgba(0,0,0,.6);
}
.video-scale-Y {
  transform:rotateY(-180deg)
}

/* 调整镜子画像大小 */
.mirror-wrap .resize-btns {
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  text-align: center;

  .btn {
    display: inline-block;
    width: 36px;
    height: 36px;
    line-height: 34px;
    margin: 0 10px;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    color: white;
    background: rgba(255,255,255,.2);
    border-radius: 50%;
    border: 2px solid white;
    cursor: pointer;
    opacity: .6;
    transition: opacity .3s ease-in-out;
    user-select: none;
    
    &:hover {
      opacity: 1;
    }
  }
}
