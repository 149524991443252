
.course-list-container {
  padding: 10px;
  box-sizing: border-box;
  max-width: 680px;
  margin: 0 auto;

  .gsteps-poster {
    width: inherit;
    text-align: center;
  }

  .course-list-content {
    padding: 10px;
  }

  /* TAB 相关 */
  .course-tabs .tab {
    display: inline-block;
    position: relative;
    font-size: 16px;
    line-height: 24px;
    margin-right: 20px;
  }

  .course-tabs .tab.active {
    color: #000;
    font-weight: bolder;
  }

  .course-tabs .tab.active::after {
    position: absolute;
    content: '';
    bottom: -5px;
    left: 0;
    right: 0;
    width: 100%;
    height: 4px;
    background: #FFB600;
    border-radius: 10px;
  }

  /* 大标题相关 */
  .headline {
    position: relative;
    margin: 40px 0 10px;
    z-index: 1;
  }

  .headline .back {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
    color: #FFEAB7;
    font-size: 28px;
    font-weight: bolder;
    text-transform: uppercase;
  }

  .headline .front {
    color: #202632;
    font-weight: bold;
  }

  /* 直播课程卡片 */
  .course-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin-bottom: 20px;
  }

  .course-item .poster {
    flex-shrink: 0;
    width: 30%;
    min-height: 130px;
    margin: 0;
    background: rgba(0, 0, 0, .1);
    border-radius: 5px;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, .1);
  }

  .course-item .course-info {
    padding-left: 10px;
    box-sizing: border-box;

    font-size: 12px;
    color: #737FA3;
    font-weight: bold;
  }

  .course-item .course-info .title {
    color: #202632;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    margin-top: 0;
  }

  .course-item .button-yellow {
    position: absolute !important;
    bottom: 0;
    right: 0;
    background: #FFB600;
    font-size: 14px;
    padding: 0 20px;
    line-height: 30px;
    height: 30px;
    border-radius: 30px;
    box-sizing: border-box;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, .3);
  }
}