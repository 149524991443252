.my-player {
  //position: fixed;
  //top: 0;
  //left: 0;
  //bottom: 0;
  //right: 0;
  position: relative;
  .play {
    background-repeat: no-repeat;
    background-size: 50px auto;
    background-position: center;
    background-image: url("../../static/img/live/play-button.png");
    z-index: 10;
    position: absolute;
    top: 0;
    //left: 0;
    bottom: 0;
    //right: 0;
    width: 100%;
    height: 100%;
  }
  video {
    //background-image: linear-gradient(45deg, #fff, #000) !important;
  }
  .video-mirror {
    //transform: rotateY(180deg);
    //transform: translateX(0px) translateZ(0px) rotateY(180deg);
    transform:scaleX(-1);
    transition: all .8s ease;
  }

  .full {
    position: absolute;
    z-index: 10;

    top: 100px;
    right: 80px;

    background-color: #fff;
    padding: 40px;
  }

  video::-webkit-media-controls-panel {
    padding-bottom: 9%;

  }
  //video::-webkit-media-controls-overlay-play-button {
  //  display: none;
  //}
  video::-webkit-media-controls-play-button {display: none}
  //video::-webkit-media-controls-timeline {
  //  display: none;
  //}
}
