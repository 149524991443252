@import "../../style/var";

.xiaoniu {
  font-family: PingFang SC,Helvetica Neue,Helvetica,Arial,Hiragino Sans GB,Microsoft Yahei,\\5FAE\8F6F\96C5\9ED1,STHeiti,\\534E\6587\7EC6\9ED1,sans-serif;
  background-color: #f8ab00;
  padding-bottom: 20px;
  text-align: center;
  .page {
    max-width: 750px;
    display: inline-block;
    text-align: left;
    .top {
      position: relative;

      .btn {
        position: absolute;
        bottom: 30px;
        // width: 175px;
        width: 60%;
        line-height: 32px;
        border: 4px solid #000000;
        border-radius: 10px;
        left: 50%;
        margin-left: -30%;
        // margin-left: -87.5px;
        font-size: 20px;
        color: #000000;
        text-align: center;
        font-weight: bolder;
        background-color: #fff;
        cursor: pointer;
      }

    }
    img.max {
      max-width: 100%;
      width: 100%;
    }
  }

  .video-box, .normal-box {
    padding: 0 30px 10px;
    margin-bottom: 30px;
    .img-wrap {
      text-align: center;
    }
    .content {
      padding: 5px 0 10px;
    }
    p {
      font-size: 13px;
      line-height: 1.8em;
      padding: 0;
      margin: 0;
    }
  }
  .video-box {
    padding: 0 30px 80px;
    background-size: 100% auto;
    background-position: bottom center;
    background-repeat: no-repeat;
    background-image: url("../../static/img/xiaoniu/title/title-bottom.png");
  }
  video {
    max-width: 100%;
    margin: 10px 0;
    border: 4px solid white;
  }

  .video-title-back {
    height: 40px;
    background-size: auto 100%;
    background-position: left center;
    background-repeat: no-repeat;
    background-image: url("../../static/img/xiaoniu/title/title-back.png");
  }
  .video-title {
    height: 100%;
    background-position: 10px center;
    background-repeat: no-repeat;
    background-size: auto 25px;

    &.free {
      background-image: url("../../static/img/xiaoniu/title/title-free.png");
    }
    &.xdp {
      background-image: url("../../static/img/xiaoniu/title/title-xdp.png");
    }
    &.bsx {
      background-image: url("../../static/img/xiaoniu/title/title-bsx.png");
    }
  }

  .intro-box {
    .content {
      margin-bottom: 20px;
    }
    .intro-item {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 30px;

      .intro-info {
        flex: 1;
        p:nth-child(1) {
          font-size: 16px;
          font-weight: bold;
        }
        p:nth-child(2) {
          font-size: 12px;
          line-height: 16px;
        }
      }

      .poster {
        width: 106px;
        margin-right: 10px;
      }
    }
  }

  .book-class-box {
    margin-bottom: 60px;
    .content {
      margin: 10px 0;
      text-align: center;
    }
  }
}

.am-modal-mask {
  background-color: rgba(0, 0, 0, 0.66) !important;
}

.result-modal {
  width: 300px !important;
  .am-modal-content {
    background-color: #F8AB00 !important;
  }
  .dash-line {
    margin: 10px 0 0;
    padding: 0;
    width: 100%;
    height: 1px;
    border-top: 2px dotted rgba(0,0,0,.6);
  }
  .msg {
    color: #000;
    font-size: 14px;
    line-height: 18px;
    text-align: left;
    margin-bottom: 20px;
    .red {
      color: #F80000;
      font-weight: bold;
    }
  }
  .btn-save {
    margin: 20px auto 10px;
    // background-color: #07C160;
    padding: 10px 30px;
    border-radius: 4px;
    color: white;
    font-size: 16px;
    font-weight: bold;
  }
}

